import Lottie from 'lottie-react';
import React from 'preact/compat';

import animatedLogo from './logo_animated.json';
import style from './style.module.scss';

export enum LogoType {
  AnimatedEvergreen = 0,
  StaticEvergreen = 1
}

interface Props {
  type: LogoType;
}

const Logo = ({ type }: Props) => {
  if (type === LogoType.AnimatedEvergreen) {
    return <Lottie class={style.company_logo} animationData={animatedLogo} loop={false} />;
  }

  return <img className={style.company_logo} src={'/assets/peslo/logo-evergreen.svg'} alt="Company Logo" />;
};

export default React.memo(Logo);
