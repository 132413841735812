import './base.scss';

import { Provider } from '@preact/prerender-data-provider';
import React from 'preact';
import { Router, RouterOnChangeArgs } from 'preact-router';

import { useCallback, useEffect, useState } from 'preact/hooks';
import { analytics } from './utils/analytics';

import Header from './components/header';

import Blog from './routes/blog';
import Home from './routes/home';
import NotFoundPage from './routes/notfound';

const App = props => {
  const [currentUrl, setCurrentUrl] = useState<string>('');

  useEffect(() => {
    analytics.initialize()
  }, [])

  /*
   *  Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](https://github.com/preactjs/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  const handleRoute = useCallback((e: RouterOnChangeArgs) => {
    setCurrentUrl(e.url);
  }, []);

  return (
    <Provider value={props}>
      <div id="app">
        <Header currentUrl={currentUrl} />
        <Router onChange={handleRoute}>
          <Home path="/" />
          <Blog path="/blog/:name" />
          <Blog path="/projects/:name" />
          <NotFoundPage default />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
