import React, { useCallback, useEffect, useMemo, useState } from 'preact/compat';

import Logo, { LogoType } from '../logo';

import style from './style.module.scss';

interface Props {
  currentUrl: string;
}

const mobileBreakpoint = 1300;

const Header = ({ currentUrl }: Props) => {
  const type = useMemo(
    () => (currentUrl.includes('blog/') ? LogoType.StaticEvergreen : LogoType.AnimatedEvergreen),
    [currentUrl]
  );

  const [reducedSize, setReducedSize] = useState(
    typeof window !== 'undefined' ? window.innerWidth < mobileBreakpoint : false
  );

  const changeScrollNav = useCallback(() => {
    if (window.scrollY >= 90) {
      setReducedSize(true);
    } else {
      setReducedSize(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', changeScrollNav);

    return () => {
      window.removeEventListener('scroll', changeScrollNav);
    };
  }, []);

  return (
    <nav
      className={`container-fluid ${style.navigation} ${style.navigation_bg}  bg-paper ${
        reducedSize ? style['nav_menu_scroll'] : ''
      }`}>
      <div className={`container-xxl d-flex ${style.navigation_container} align-items-center`}>
        <a href="/" className={style.navigation_icon_container}>
          <Logo type={type} />
        </a>
        <a className={style.back_home_link} href="https://peslostudios.com">
          Main site
        </a>
      </div>
    </nav>
  );
};

export default React.memo(Header);
