import * as amplitude from '@amplitude/analytics-browser';

const initialize = () => {
  amplitude.init('f1af3b3180af18cd5bb858ea7afc855f', undefined, {
    defaultTracking: {
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true
    }
  });
};

export const analytics = {
  initialize,
};
